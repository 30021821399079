// @flow

import style from "./style.module.scss";

import React, { PureComponent } from "react";
import Img from "gatsby-image/withIEPolyfill";
import debounce from "lodash.debounce";
import Carousel from "nuka-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { Overlay } from "../";

const DEBOUNCE_WAIT = 100;
const DEBOUNCE_MAX_WAIT = 500;

/**
 * Lightbox component
 */
export default class Lightbox extends PureComponent<*, *> {
    state = {
        transitionMode: "fade",
        width: 0,
        height: 0,
    };

    /**
     * Update the target size
     */
    updateTargetSize = debounce(
        () =>
            this.setState({
                width: Math.min(
                    window.innerWidth - (window.innerWidth >= 992 ? 64 : 16),
                    1200,
                ),
                height: Math.min(window.innerHeight - 160, 1200),
            }),
        DEBOUNCE_WAIT,
        {
            leading: true,
            maxWait: DEBOUNCE_MAX_WAIT,
            trailing: true,
        },
    );

    /**
     * Get all images with the right sizing box attached
     */
    get slides() {
        const { images } = this.props;
        const { width, height } = this.state;
        const windowAspect = width / height;

        return images.map(image => ({
            image,
            dimensions:
                windowAspect > image.large.aspectRatio
                    ? {
                          width: Math.round(height * image.large.aspectRatio),
                          height,
                      }
                    : {
                          width,
                          height: Math.round(width / image.large.aspectRatio),
                      },
        }));
    }

    /**
     * Register updater
     */
    componentDidMount() {
        window.addEventListener("resize", this.updateTargetSize);
        this.updateTargetSize();
        this.setState({
            transitionMode: "scroll3d",
        });
    }

    /**
     * Destroy updater
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateTargetSize);
    }

    /**
     * Handle previous
     */
    handlePrevious() {
        const { images, current, onChange } = this.props;

        if (current - 1 < 0) {
            onChange(images.length - 1);
            return;
        }

        onChange(current - 1);
    }

    /**
     * Handle next
     */
    handleNext() {
        const { images, current, onChange } = this.props;

        if (current + 1 >= images.length) {
            onChange(0);
            return;
        }

        onChange(current + 1);
    }

    render() {
        const { current, onChange } = this.props;
        const { transitionMode } = this.state;

        return (
            <Overlay>
                <div className={style.element}>
                    <Carousel
                        enableKeyboardControls
                        withoutControls
                        wrapAround
                        slideIndex={current}
                        afterSlide={onChange}
                        transitionMode={transitionMode}
                    >
                        {this.slides.map(slide => (
                            <div key={slide.image.id} className={style.wrapper}>
                                <div
                                    className={style.slide}
                                    style={{
                                        width: `${slide.dimensions.width}px`,
                                        height: `${slide.dimensions.height}px`,
                                    }}
                                >
                                    <button
                                        onClick={this.handlePrevious.bind(this)}
                                        className={style.previous}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                                    <button
                                        className={style.button}
                                        onClick={() => onChange()}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                    <Img fluid={slide.image.large} />
                                    {slide.image.description && (
                                        <div className={style.description}>
                                            <p>{slide.image.description}</p>
                                        </div>
                                    )}
                                    <button
                                        onClick={this.handleNext.bind(this)}
                                        className={style.next}
                                    >
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                        />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </Overlay>
        );
    }
}
