// @flow

import style from "./style.module.scss";

import classnames from "classnames";
import React from "react";
import Img from "gatsby-image/withIEPolyfill";

/**
 * Title
 */
const Title = ({ title, image }: *) => (
    <div className={style.element}>
        <Img
            className={style.image}
            fluid={{
                ...image.fluid,
                base64: image.sqip.dataURI,
            }}
        />
        <div className={style.content}>
            <h1 className={classnames("title", style.title)}>{title}</h1>
        </div>
    </div>
);

export default Title;
