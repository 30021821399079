// @flow

import style from "./style.module.scss";

import classnames from "classnames";
import React, { PureComponent } from "react";
import { Container, Row, Col } from "reactstrap";
import Img from "gatsby-image/withIEPolyfill";

import { Button, ImageSlider, RichText } from "../";

const TRANSITION_INTERVAL = 5000;

/**
 * Hero
 */
export default class Hero extends PureComponent<*, *> {
    // Initial state
    state = {
        shown: 0,
    };

    // Set timeout
    transitionTimeout: *;

    /**
     * Get merged images distributed by 2
     */
    get images() {
        const { sections } = this.props;
        const unmerged = sections.map(section => [...section.images]);
        const result = [];

        while (unmerged.length) {
            for (let i = 0; i < unmerged.length; i++) {
                for (let j = 0; j < 2 && j < unmerged[i].length; j++) {
                    result.push(unmerged[i].shift());
                }

                if (!unmerged[i].length) {
                    unmerged.splice(i, 1);
                }
            }
        }

        return result;
    }

    /**
     * Go to next
     */
    next() {
        const { shown } = this.state;

        this.setState(
            {
                shown: shown >= this.images.length - 1 ? 0 : shown + 1,
            },
            this.start.bind(this),
        );
    }

    /**
     * Start transitioning
     */
    start() {
        this.stop();

        this.transitionTimeout = setTimeout(
            this.next.bind(this),
            TRANSITION_INTERVAL,
        );
    }

    /**
     * Stop transitioning
     */
    stop() {
        if (this.transitionTimeout) {
            clearTimeout(this.transitionTimeout);
        }
    }

    /**
     * Start timer
     */
    componentDidMount() {
        this.start();
    }

    /**
     * Destroy timer
     */
    componentWillUnmount() {
        this.stop();
    }

    /**
     * Render
     */
    render() {
        const { id, title, sections } = this.props;
        const { shown } = this.state;
        const images = this.images;
        const titleImage = images[shown];

        return (
            <div className={style.element}>
                <div className={style.heading}>
                    <div className={style.title}>
                        <Img
                            className={style.titleImage}
                            fluid={{
                                ...titleImage.large,
                                base64: titleImage.sqip.dataURI,
                            }}
                        />
                        <div className={style.content}>
                            <h1
                                className={classnames(
                                    "title",
                                    style.innerTitle,
                                )}
                            >
                                {title}
                            </h1>
                        </div>
                    </div>
                    {/* Gallery for large screens */}
                    <ImageSlider
                        id={`md-${id}`}
                        className={classnames(
                            "d-none d-md-block",
                            style.slider,
                        )}
                        buttonClassName={style.next}
                        images={images}
                        current={shown}
                        onClick={shown =>
                            this.setState({ shown }, this.start.bind(this))
                        }
                    />
                </div>
                <div className={style.sections}>
                    <div className="d-md-none">
                        {sections.map(section => (
                            <div key={section.id} className={style.section}>
                                <ImageSlider
                                    id={section.id}
                                    className={style.slider}
                                    buttonClassName={style.button}
                                    images={section.images}
                                />
                                <Container>
                                    <div>
                                        <h2 className="title">
                                            {section.title}
                                        </h2>
                                        <RichText content={section.text.json} />
                                        <Button
                                            color="primary"
                                            to={`/${section.cta.page.slug}/`}
                                        >
                                            {section.cta.label}
                                        </Button>
                                    </div>
                                </Container>
                            </div>
                        ))}
                    </div>
                    <div className="d-none d-md-block">
                        <Container>
                            <Row>
                                {sections.map(({ id, title, text }) => (
                                    <Col key={`content-${id}`} md={4}>
                                        <div>
                                            <h2 className="title">{title}</h2>
                                            <RichText content={text.json} />
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                {sections.map(({ id, cta }) => (
                                    <Col key={`cta-${id}`} md={4}>
                                        {cta && (
                                            <Button
                                                color="primary"
                                                to={`/${cta.page.slug}/`}
                                            >
                                                {cta.label}
                                            </Button>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}
