// @flow

import style from "./style.module.scss";

import classnames from "classnames";
import tinycolor from "tinycolor2";
import React from "react";
import Img from "gatsby-image/withIEPolyfill";
import { Container, Row, Col } from "reactstrap";

import { Button, RichText } from "../";

/**
 * Feature
 */
const Feature = ({ type, backgroundColor, title, body, cta, image }: *) => (
    <div
        className={classnames(style.element, style[type])}
        style={{ backgroundColor: backgroundColor }}
    >
        <Container className={style.content} fluid>
            <Row>
                <Col md={6}>
                    <div className={style.inner}>
                        <h2 className="title">{title}</h2>
                        <RichText content={body.json} />
                        {cta && (
                            <Button
                                className={style.button}
                                to={`/${cta.page.slug}/`}
                                color={
                                    (type === "dark" && "inverted") || "default"
                                }
                            >
                                {cta.label}
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
        <div className={style.background}>
            <Img
                className={style.image}
                fluid={{
                    ...image.fluid,
                    base64: image.sqip.dataURI,
                }}
            />
            <div
                style={{
                    backgroundImage: `linear-gradient(${tinycolor(
                        backgroundColor,
                    ).toRgbString()}, ${tinycolor(backgroundColor)
                        .setAlpha(0)
                        .toRgbString()})`,
                }}
                className={style.backdrop}
            />
            <div
                style={{
                    backgroundImage: `linear-gradient(to right, ${tinycolor(
                        backgroundColor,
                    ).toRgbString()}, ${tinycolor(backgroundColor)
                        .setAlpha(0)
                        .toRgbString()})`,
                }}
                className={style.backdropBig}
            />
        </div>
    </div>
);

export default Feature;
