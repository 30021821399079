// @flow

import style from "./style.module.scss";

import classnames from "classnames";
import React, { type Node } from "react";
import { Link } from "gatsby";

type Props = {
    children: Node,
    to: string,
    color: "default" | "primary" | "inverted",
    className?: ?string,
};

/**
 * Button
 */
const Button = ({ children, to, color, className }: Props) => (
    <Link
        className={classnames(style.element, className, {
            [style[color]]: true,
        })}
        to={to}
    >
        <div className={classnames(style.arrow, "arrow")} />
        <div className={classnames(style.negative, "negative")} />
        <div className={classnames(style.content, "content")}>{children}</div>
    </Link>
);

Button.defaultProps = {
    color: "default",
};

export default Button;
