// @flow

import style from "./style.module.scss";

import classnames from "classnames";
import React, { PureComponent } from "react";
import { Container } from "reactstrap";
import Img from "gatsby-image/withIEPolyfill";
import perfectLayout from "perfect-layout/dist/perfectLayout";

import { Button, Lightbox } from "../";

const STAGES = {
    md: 1400,
    lg: 1000,
    xl: 800,
};

/**
 * Gallery
 */
export default class Gallery extends PureComponent<*, *> {
    // Initial state
    state = {
        active: null,
        mounted: false,
    };

    /**
     * Only load on mount
     */
    componentDidMount() {
        this.setState({ mounted: true });
    }

    /**
     * Get images for stage
     */
    getImagesForStage(stage: "md" | "lg" | "xl") {
        const { images } = this.props;

        const layout = perfectLayout(
            images.map((data, index) => ({
                data: { ...data, index },
                ratio: data.thumbnail.aspectRatio,
            })),
            2000,
            STAGES[stage],
            { margin: 0 },
        );

        return ((layout.length && layout[0].length && layout) || [layout]).map(
            (row, index) => {
                const totalWidth = row.reduce(
                    (accumulator, current) => (accumulator += current.width),
                    0,
                );

                return (
                    <div className={style.row} key={`row-${index}`}>
                        {row.map(({ data, width, height }) => (
                            <div
                                onClick={() =>
                                    this.setState({
                                        active: data.index,
                                    })
                                }
                                key={data.id}
                                className={style.thumbnail}
                                style={{
                                    width: `${((width / totalWidth) * 2000) /
                                        20}%`,
                                    paddingTop: `${((height / totalWidth) *
                                        2000) /
                                        20}%`,
                                }}
                            >
                                <div className={style.inner}>
                                    <Img
                                        className={style.image}
                                        fluid={data.thumbnail}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                );
            },
        );
    }

    /**
     * Render
     */
    render() {
        const { images, ctas } = this.props;
        const { active, mounted } = this.state;

        if (!mounted) {
            return <div className={style.element} />;
        }

        return (
            <div className={style.element}>
                <Container>
                    <div className="d-md-none">
                        {images.map((image, index) => (
                            <div
                                onClick={() =>
                                    this.setState({
                                        active: index,
                                    })
                                }
                                key={image.id}
                                className={style.mobileThumbnail}
                            >
                                <Img fluid={image.thumbnail} />
                            </div>
                        ))}
                    </div>
                    <div
                        className={classnames(
                            style.gallery,
                            "d-none",
                            "d-md-block",
                            "d-lg-none",
                        )}
                    >
                        {this.getImagesForStage("md")}
                    </div>
                    <div
                        className={classnames(
                            style.gallery,
                            "d-none",
                            "d-lg-block",
                            "d-xl-none",
                        )}
                    >
                        {this.getImagesForStage("lg")}
                    </div>
                    <div
                        className={classnames(
                            style.gallery,
                            "d-none",
                            "d-xl-block",
                        )}
                    >
                        {this.getImagesForStage("xl")}
                    </div>
                    {ctas?.length && (
                        <div className={style.ctas}>
                            {ctas.map(cta => (
                                <Button key={cta.id} to={`/${cta.page.slug}/`}>
                                    {cta.label}
                                </Button>
                            ))}
                        </div>
                    )}
                </Container>
                {(typeof active === "number" && (
                    <Lightbox
                        images={images}
                        current={active}
                        onChange={active =>
                            this.setState({
                                active,
                            })
                        }
                    />
                )) ||
                    undefined}
            </div>
        );
    }
}
