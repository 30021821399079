// @flow

import style from "./style.module.scss";

import classnames from "classnames";
import React from "react";
import Img from "gatsby-image/withIEPolyfill";
import { Container } from "reactstrap";

/**
 * Image
 */
const Image = ({ image, fullWidth }: *) => (
    <div
        className={classnames(style.element, {
            [style.fullWidth]: fullWidth,
        })}
    >
        <Container fluid={fullWidth}>
            <Img fluid={image.fluid} />
        </Container>
    </div>
);

export default Image;
