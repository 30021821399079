// @flow

import style from "./style.module.scss";

import React, { PureComponent } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button,
    FormFeedback,
} from "reactstrap";

import { RichText } from "../";

const getInitialFormState = () =>
    ["name", "email", "phone", "subject", "message"].reduce(
        (accumulator, current) => ({
            ...accumulator,
            [current]: {
                value: "",
                error: false,
                touched: false,
            },
        }),
        {},
    );

const encode = data =>
    Object.keys(data)
        .filter(key => !!data[key])
        .map(
            key =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                    (data: any)[key].value || (data: any)[key],
                )}`,
        )
        .join("&");

/**
 * ContactForm
 */
export default class ContactForm extends PureComponent<*, *> {
    // Initial state
    state = {
        submitState: "",
        fields: getInitialFormState(),
    };

    validate(force: boolean = false) {
        const { fields } = this.state;
        let hasError = false;

        const checked = Object.keys(fields).reduce((accumulator, current) => {
            const error = !fields[current].value.trim().length;
            const touched = force ? true : fields[current].touched;

            hasError = hasError || error;

            return {
                ...accumulator,
                [current]: {
                    ...fields[current],
                    error,
                    touched,
                },
            };
        }, {});

        this.setState({
            fields: checked,
        });

        return !hasError;
    }

    handleChange(event: *) {
        const { fields } = this.state;
        const { name, value } = event.target;

        this.setState(
            {
                fields: {
                    ...fields,
                    [name]: {
                        ...fields[name],
                        value,
                        touched: true,
                    },
                },
            },
            this.validate.bind(this),
        );
    }

    handleSubmit(event: *) {
        event.preventDefault();

        if (this.validate(true)) {
            this.setState({
                submitState: "loading",
            });

            const { fields } = this.state;

            fetch("/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({ "form-name": "contact", ...fields }),
            })
                .then(({ status }) => {
                    if (status < 400) {
                        this.setState({
                            fields: getInitialFormState(),
                            submitState: "succes",
                        });
                    } else {
                        this.setState({
                            submitState: "error",
                        });
                    }
                })
                .catch(() =>
                    this.setState({
                        submitState: "error",
                    }),
                );
        }
    }

    /**
     * Render
     */
    render() {
        const { title, text } = this.props;
        const { fields, submitState } = this.state;

        return (
            <div className={style.element}>
                <Container>
                    <Row>
                        <Col
                            xl={{ size: 6, offset: 3 }}
                            lg={{ size: 8, offset: 2 }}
                            md={{ size: 10, offset: 1 }}
                        >
                            <h1 className="title">{title}</h1>
                            {text && <RichText content={text.json} />}
                            <Form
                                name="contact"
                                data-netlify="true"
                                onSubmit={this.handleSubmit.bind(this)}
                                className={style.form}
                                noValidate
                            >
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input
                                                disabled={
                                                    submitState === "loading"
                                                }
                                                name="name"
                                                type="text"
                                                placeholder="naam"
                                                onChange={this.handleChange.bind(
                                                    this,
                                                )}
                                                invalid={
                                                    fields.name.touched &&
                                                    fields.name.error
                                                }
                                                value={fields.name.value}
                                            />
                                            <FormFeedback>
                                                Dit veld is verplicht!
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input
                                                disabled={
                                                    submitState === "loading"
                                                }
                                                name="email"
                                                type="email"
                                                placeholder="e-mailadres"
                                                onChange={this.handleChange.bind(
                                                    this,
                                                )}
                                                invalid={
                                                    fields.email.touched &&
                                                    fields.email.error
                                                }
                                                value={fields.email.value}
                                            />
                                            <FormFeedback>
                                                Dit veld is verplicht!
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input
                                                disabled={
                                                    submitState === "loading"
                                                }
                                                name="phone"
                                                type="text"
                                                placeholder="telefoonnummer"
                                                onChange={this.handleChange.bind(
                                                    this,
                                                )}
                                                invalid={
                                                    fields.phone.touched &&
                                                    fields.phone.error
                                                }
                                                value={fields.phone.value}
                                            />
                                            <FormFeedback>
                                                Dit veld is verplicht!
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input
                                                disabled={
                                                    submitState === "loading"
                                                }
                                                name="subject"
                                                type="text"
                                                placeholder="onderwerp"
                                                onChange={this.handleChange.bind(
                                                    this,
                                                )}
                                                invalid={
                                                    fields.subject.touched &&
                                                    fields.subject.error
                                                }
                                                value={fields.subject.value}
                                            />
                                            <FormFeedback>
                                                Dit veld is verplicht!
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Input
                                                disabled={
                                                    submitState === "loading"
                                                }
                                                name="message"
                                                type="textarea"
                                                rows="8"
                                                placeholder="bericht"
                                                onChange={this.handleChange.bind(
                                                    this,
                                                )}
                                                invalid={
                                                    fields.message.touched &&
                                                    fields.message.error
                                                }
                                                value={fields.message.value}
                                            />
                                            <FormFeedback>
                                                Dit veld is verplicht!
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div>
                                    {submitState === "succes" && (
                                        <p className={style.success}>
                                            Bedankt voor uw bericht! Ik neem zo
                                            snel als mogelijk contact met u op.
                                        </p>
                                    )}
                                    {submitState === "error" && (
                                        <p className={style.error}>
                                            Bedankt voor uw bericht! Ik neem zo
                                            snel als mogelijk contact met u op.
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        disabled={submitState === "loading"}
                                    >
                                        Verzend
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
