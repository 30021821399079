// @flow

import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import {
    Feature,
    Title,
    Section,
    Gallery,
    Hero,
    Image,
    ContactForm,
} from "../../components";

const renderTopics = topics =>
    topics &&
    topics.map(({ __typename: typename, ...topic }, index) => {
        const shared = {
            key: index,
        };

        if (typename === "ContentfulFeature") {
            return <Feature {...shared} {...topic} />;
        }

        if (typename === "ContentfulTitle") {
            return <Title {...shared} {...topic} />;
        }

        if (typename === "ContentfulSection") {
            return <Section {...shared} {...topic} />;
        }

        if (typename === "ContentfulGallery") {
            return <Gallery {...shared} {...topic} />;
        }

        if (typename === "ContentfulHero") {
            return <Hero {...shared} {...topic} />;
        }

        if (typename === "ContentfulImage") {
            return <Image {...shared} {...topic} />;
        }

        if (typename === "ContentfulContactForm") {
            return <ContactForm {...shared} {...topic} />;
        }
    });

/**
 * Page template
 */
const Page = ({ page, site }: *) => (
    <>
        <Helmet>
            <title>{`${site.siteMetadata.title} - ${page.title}`}</title>
        </Helmet>
        {renderTopics(page.content)}
    </>
);

/**
 * Page fragment
 */
export const fragment = graphql`
    fragment PageContent on ContentfulPage {
        title
        content {
            __typename
            ... on ContentfulFeature {
                title
                type
                body {
                    json
                }
                backgroundColor
                cta {
                    label
                    page {
                        slug
                    }
                }
                image {
                    sqip(numberOfPrimitives: 2, blur: 50) {
                        dataURI
                    }
                    fluid(maxWidth: 800, maxHeight: 800) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
            ... on ContentfulTitle {
                title
                image {
                    sqip(numberOfPrimitives: 2, blur: 50) {
                        dataURI
                    }
                    fluid(maxWidth: 1200) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
            ... on ContentfulSection {
                title
                content {
                    json
                }
                cta {
                    label
                    page {
                        slug
                    }
                }
            }
            ... on ContentfulGallery {
                name
                images {
                    id
                    description
                    thumbnail: fluid(maxWidth: 510) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                    large: fluid(maxWidth: 1200) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                ctas {
                    id
                    label
                    page {
                        slug
                    }
                }
            }
            ... on ContentfulHero {
                id
                title
                sections {
                    id
                    title
                    text {
                        json
                    }
                    cta {
                        label
                        page {
                            slug
                        }
                    }
                    images {
                        id
                        description
                        sqip(numberOfPrimitives: 2, blur: 50) {
                            dataURI
                        }
                        thumbnail: fluid(maxWidth: 380, maxHeight: 254) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                        large: fluid(maxWidth: 1200) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
            ... on ContentfulImage {
                id
                image {
                    sqip(numberOfPrimitives: 2, blur: 50) {
                        dataURI
                    }
                    fluid(maxWidth: 1200) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                fullWidth
            }
            ... on ContentfulContactForm {
                title
                text {
                    json
                }
            }
        }
    }
`;

export default Page;
