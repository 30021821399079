// @flow

// Bootstrap global styling
import "./index.scss";
import style from "./style.module.scss";

import React, { Fragment, type Node } from "react";

import { Header, Footer } from "../";

type Props = {
    children: Node,
};

/**
 * Page layout and global styling
 */
const Layout = ({ children }: Props) => (
    <Fragment>
        <Header />
        <main className={style.main}>{children}</main>
        <Footer />
    </Fragment>
);

export default Layout;
