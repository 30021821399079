// @flow

import style from "./style.module.scss";

import React, { PureComponent } from "react";
import {
    Button,
    Container,
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    FormFeedback,
} from "reactstrap";

type Field = {
    value: string,
    error: boolean,
    touched: boolean,
};

type Props = {};

type State = {
    fields: {
        [key: string]: Field,
    },
    submitState?: ?("loading" | "succes" | "error"),
};

const getInitialFormState = () =>
    ["name", "email", "phone", "subject", "message"].reduce(
        (accumulator, current) => ({
            ...accumulator,
            [current]: {
                value: "",
                error: false,
                touched: false,
            },
        }),
        {},
    );

const encode = data =>
    Object.keys(data)
        .filter(key => !!data[key])
        .map(
            key =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                    (data: any)[key].value || (data: any)[key],
                )}`,
        )
        .join("&");

/**
 * Footer
 */
export default class Footer extends PureComponent<Props, State> {
    // Initial state
    state = {
        fields: getInitialFormState(),
        submitState: null,
    };

    validate(force: boolean = false) {
        const { fields } = this.state;
        let hasError = false;

        const checked = Object.keys(fields).reduce((accumulator, current) => {
            const error = !fields[current].value.trim().length;
            const touched = force ? true : fields[current].touched;

            hasError = hasError || error;

            return {
                ...accumulator,
                [current]: {
                    ...fields[current],
                    error,
                    touched,
                },
            };
        }, {});

        this.setState({
            fields: checked,
        });

        return !hasError;
    }

    handleChange(event: *) {
        const { fields } = this.state;
        const { name, value } = event.target;

        this.setState(
            {
                fields: {
                    ...fields,
                    [name]: {
                        ...fields[name],
                        value,
                        touched: true,
                    },
                },
            },
            this.validate.bind(this),
        );
    }

    handleSubmit(event: *) {
        event.preventDefault();

        if (this.validate(true)) {
            this.setState({
                submitState: "loading",
            });

            const { fields } = this.state;

            fetch("/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({ "form-name": "contact", ...fields }),
            })
                .then(({ status }) => {
                    if (status < 400) {
                        this.setState({
                            fields: getInitialFormState(),
                            submitState: "succes",
                        });
                    } else {
                        this.setState({
                            submitState: "error",
                        });
                    }
                })
                .catch(() =>
                    this.setState({
                        submitState: "error",
                    }),
                );
        }
    }

    /**
     * Render
     */
    render() {
        const { fields, submitState } = this.state;

        return (
            <footer className={style.element}>
                <Container>
                    <h1 className="title">Contact</h1>
                    <p>
                        U had graag een vrijblijvende offerte, of andere
                        informatie? Aarzel dan niet om fotograaf Florian te
                        contacteren.
                    </p>
                    <Form
                        name="contact"
                        data-netlify="true"
                        onSubmit={this.handleSubmit.bind(this)}
                        className={style.form}
                        noValidate
                    >
                        <Row>
                            <Col md={6} lg={4}>
                                <FormGroup>
                                    <Input
                                        disabled={submitState === "loading"}
                                        name="name"
                                        type="text"
                                        placeholder="naam"
                                        onChange={this.handleChange.bind(this)}
                                        invalid={
                                            fields.name.touched &&
                                            fields.name.error
                                        }
                                        value={fields.name.value}
                                    />
                                    <FormFeedback>
                                        Dit veld is verplicht!
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={4}>
                                <FormGroup>
                                    <Input
                                        disabled={submitState === "loading"}
                                        name="email"
                                        type="email"
                                        placeholder="e-mailadres"
                                        onChange={this.handleChange.bind(this)}
                                        invalid={
                                            fields.email.touched &&
                                            fields.email.error
                                        }
                                        value={fields.email.value}
                                    />
                                    <FormFeedback>
                                        Dit veld is verplicht!
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={4}>
                                <FormGroup>
                                    <Input
                                        disabled={submitState === "loading"}
                                        name="phone"
                                        type="text"
                                        placeholder="telefoonnummer"
                                        onChange={this.handleChange.bind(this)}
                                        invalid={
                                            fields.phone.touched &&
                                            fields.phone.error
                                        }
                                        value={fields.phone.value}
                                    />
                                    <FormFeedback>
                                        Dit veld is verplicht!
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={4}>
                                <FormGroup>
                                    <Input
                                        disabled={submitState === "loading"}
                                        name="subject"
                                        type="text"
                                        placeholder="onderwerp"
                                        onChange={this.handleChange.bind(this)}
                                        invalid={
                                            fields.subject.touched &&
                                            fields.subject.error
                                        }
                                        value={fields.subject.value}
                                    />
                                    <FormFeedback>
                                        Dit veld is verplicht!
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={12} lg={8}>
                                <FormGroup>
                                    <Input
                                        disabled={submitState === "loading"}
                                        name="message"
                                        type="text"
                                        placeholder="bericht"
                                        onChange={this.handleChange.bind(this)}
                                        invalid={
                                            fields.message.touched &&
                                            fields.message.error
                                        }
                                        value={fields.message.value}
                                    />
                                    <FormFeedback>
                                        Dit veld is verplicht!
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div>
                            {submitState === "succes" && (
                                <p className={style.success}>
                                    Bedankt voor uw bericht! Ik neem zo snel als
                                    mogelijk contact met u op.
                                </p>
                            )}
                            {submitState === "error" && (
                                <p className={style.error}>
                                    Bedankt voor uw bericht! Ik neem zo snel als
                                    mogelijk contact met u op.
                                </p>
                            )}
                        </div>
                        <div>
                            <Button
                                color="primary"
                                type="submit"
                                disabled={submitState === "loading"}
                            >
                                Verzend
                            </Button>
                        </div>
                    </Form>
                    <div className={style.info}>
                        <p>
                            <span>
                                <strong>Fotograaf</strong> Florian Van Eenoo
                            </span>
                            <a href="tel:+32477223760">0477 223 760</a>
                            <a href="mailto:fotograafflorian@gmail.com">
                                fotograafflorian@gmail.com
                            </a>
                        </p>
                        <p>
                            <span>
                                <strong>studio</strong> Molenstraat 19
                            </span>
                            <span>8750 Wingene</span>
                        </p>
                        <p>
                            <span>
                                BE 0875688086
                            </span>
                        </p>
                    </div>
                </Container>
            </footer>
        );
    }
}
