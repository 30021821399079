// @flow

const PREFIX = "ff";
const NAME = "close";
const WIDTH = 512;
const HEIGHT = 512;
const LIGATURES = [];
const UNICODE = null;
const PATH =
    "M511.928187 492.162076 275.762421 255.523978 510.514021 20.3028756 490.715693 0.464951398 255.964094 235.686054 21.212494 0.464951398 1.41416626 20.3028756 236.165766 255.523978 0 492.162076 19.7983277 512 255.964094 275.361903 490.715693 512z";

export default {
    prefix: PREFIX,
    iconName: NAME,
    icon: [WIDTH, HEIGHT, LIGATURES, UNICODE, PATH],
};
