// @flow

import style from "./style.module.scss";

import classnames from "classnames";
import debounce from "lodash.debounce";
import React, { PureComponent } from "react";
import Img from "gatsby-image/withIEPolyfill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

type Props = {
    id: string,
    className?: ?string,
    buttonClassName?: ?string,
    images: *,
    current?: ?number,
    onClick?: (target: number) => *,
};

type State = {
    slots: number,
    current: number,
};

/**
 * ImageSlider
 */
export default class ImageSlider extends PureComponent<Props, State> {
    // Initial state
    state = {
        slots: 2,
        current: this.props.current || 0,
    };

    /**
     * Figure out by which step and how many offset there is
     */
    resize = debounce(() => {
        this.setState({
            slots: window.innerWidth < 768 ? 2 : 5,
        });
    }, 50);

    /**
     * Get current
     */
    get current() {
        if (this.props.current) {
            return this.props.current;
        }

        return this.state.current;
    }

    /**
     * Calculate the offset based on current and slots
     */
    get offset() {
        const { images } = this.props;
        const { slots } = this.state;

        return `-${(100 / slots) *
            Math.max(Math.min(this.current + 1, images.length) - slots, 0)}%`;
    }

    /**
     * Handle go to image
     */
    handleGoto(target: number) {
        const { onClick } = this.props;

        if (onClick) {
            onClick(target);
        }

        this.setState({
            current: target,
        });
    }

    /**
     * On Component mount
     */
    componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize);
    }

    /**
     * Before destruction
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    /**
     * Render
     */
    render() {
        const { id, images, className, buttonClassName } = this.props;
        const { slots } = this.state;

        return (
            <div className={classnames(style.element, className)}>
                <div className={style.wrapper}>
                    <div
                        className={style.slider}
                        style={{ transform: `translateX(${this.offset})` }}
                    >
                        {images.map((image, index) => (
                            <div
                                key={`${id}-${image.id}-${index}`}
                                className={style.image}
                                onClick={() => this.handleGoto(index)}
                            >
                                <Img
                                    fluid={{
                                        ...image.thumbnail,
                                        base64: image.sqip.dataURI,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {buttonClassName && images.length > slots && (
                    <button
                        className={classnames(buttonClassName)}
                        onClick={() =>
                            this.handleGoto(
                                this.current >= images.length - 1
                                    ? 0
                                    : this.current + 1,
                            )
                        }
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                )}
            </div>
        );
    }
}
