// @flow

import style from "./style.module.scss";

import React from "react";
import { Container, Row, Col } from "reactstrap";

import { RichText, Button } from "../";

/**
 * Section
 */
const Section = ({ title, content, cta }: *) => (
    <div className={style.element}>
        <Container>
            <Row>
                <Col lg={{ size: 8, offset: 2 }}>
                    <div className={style.content}>
                        <h2 className="title">{title}</h2>
                        <RichText content={content.json} />
                        {cta && (
                            <Button
                                className={style.button}
                                to={`/${cta.page.slug}/`}
                            >
                                {cta.label}
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);

export default Section;
