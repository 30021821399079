// @flow

import style from "./style.module.scss";

import React from "react";

const Overlay = ({ children }: *) => (
    <div className={style.element}>{children}</div>
);

export default Overlay;
