// @flow

import React from "react";
import { MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Strong = text => <strong>{text}</strong>;

/**
 * RichText
 */
const RichText = ({ content, className }: *) => (
    <div className={className}>
        {documentToReactComponents(content, {
            renderMark: {
                [MARKS.BOLD]: Strong,
            },
        })}
    </div>
);

RichText.defaultProps = {
    className: "",
};

export default RichText;
