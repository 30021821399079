// @flow

import logo from "../../images/logo-big.svg";

import style from "./style.module.scss";

import classnames from "classnames";
import React, { PureComponent } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Button } from "reactstrap";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faEnvelope,
    faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import { ffClose } from "../../icons";

type State = {
    open: boolean,
};

const PagesNavigation = ({ onClick }: *) => (
    <StaticQuery
        query={graphql`
            {
                contentfulNavigation(name: { eq: "Main" }) {
                    links {
                        id
                        label
                        page {
                            slug
                        }
                    }
                }
            }
        `}
        render={({ contentfulNavigation: { links } }) => (
            <nav className={classnames(style.navigation)}>
                <ul>
                    {links.map(link => (
                        <li key={link.id}>
                            <Link onClick={onClick} to={`/${link.page.slug}/`}>
                                {link.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        )}
    />
);

const SideBar = () => (
    <div className={style.sidebar}>
        <a href="https://www.facebook.com/fotograaf.florian/">
            <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://www.instagram.com/fotograafflorian/">
            <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.linkedin.com/in/florian-van-eenoo%E2%80%8F-02b6121a/">
            <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        <a href="mailto:fotograafflorian@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} />
        </a>
        <a href="tel:+32477223760">
            <FontAwesomeIcon icon={faMobileAlt} />
        </a>
    </div>
);

/**
 * Header
 */
export default class Header extends PureComponent<*, State> {
    // Initial state
    state = {
        open: false,
    };

    /**
     * Render
     */
    render() {
        const { open } = this.state;

        return (
            <header
                className={classnames(style.element, {
                    [style.open]: open,
                })}
            >
                <div className={style.wrapper}>
                    <div className={style.brand}>
                        <Link
                            onClick={() => this.setState({ open: false })}
                            to="/"
                        >
                            <img src={logo} alt="Fotograaf Florian" />
                        </Link>
                    </div>
                    <Button
                        onClick={() =>
                            this.setState({
                                open: !open,
                            })
                        }
                        size="lg"
                        className={style.toggle}
                        color="link"
                    >
                        <FontAwesomeIcon icon={open ? ffClose : faBars} />
                    </Button>
                    <PagesNavigation
                        onClick={() =>
                            this.setState({
                                open: false,
                            })
                        }
                    />
                </div>
                <SideBar />
            </header>
        );
    }
}
